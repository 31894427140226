import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserProfile } from '../models/user-profile.model';
import { timeout } from 'rxjs/operators';
import { BEARER_ONLY, BYPASS_HEAD } from '../interceptor/token.interceptor';

@Injectable({
  providedIn: 'root',
})
export class AccountService {

  constructor(
    private http: HttpClient
  ) { }

  userProfile(): Observable<any> {
    return this.http
    .get<any>(environment.apiUrl + '/api/v1/admin/details');
  }

  changePassword(item: any): Observable<any> {
    return this.http
      .post<any>(environment.apiUrl + '/v1/change-password', JSON.stringify(item));
  }
  
  getDeliveries(page: any, status: any, dateFilter: any): Observable<any> {
    return this.http
    .get<any>(environment.apiUrl + `/api/v1/admin/deliveries?page=${page}&filter[deliveryStatus.deliveryStatus]=${status}&filter[targetPickup]=${dateFilter}`);
  }

  getDeliveryByTracking(referenceId: any): Observable<any> {
    return this.http
    .get<any>(environment.apiUrl + `/api/v1/admin/deliveries?filter[referenceId]=${referenceId}`);
  }

  getUnassignedDeliveries(){
    return this.http
    .get<any>(environment.apiUrl + '/api/v1/admin/unassign-deliveries');
  }

  getRiders(): Observable<any> {
    return this.http
    .get<any>(environment.apiUrl + '/api/v1/admin/riders');
  }

  updateRiderStatus(item: any): Observable<any> {
    return this.http
      .post<any>(environment.apiUrl + '/api/v1/admin/update-riders-status', JSON.stringify(item));
  }

  assignDelivery(item: any): Observable<any> {
    return this.http
      .post<any>(environment.apiUrl + '/api/v1/admin/assigns-riders', JSON.stringify(item));
  }

  getRoles(): Observable<any> {
    return this.http
    .get<any>(environment.apiUrl + '/api/v1/admin/role/roles-permissions');
  }

  getPermissions(): Observable<any> {
    return this.http
    .get<any>(environment.apiUrl + '/api/v1/admin/role/permissions');
  }

  getUsers(): Observable<any> {
    return this.http
    .get<any>(environment.apiUrl + '/api/v1/admin/users');
  }

  createRole(item: any): Observable<any> {
    return this.http
      .post<any>(environment.apiUrl + '/api/v1/admin/role', JSON.stringify(item));
  }

  removeRole(item: any){
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(item)
    };

    return new Promise(async (resolve, reject) => {
      this.http.delete<any>(environment.apiUrl + '/api/v1/admin/role', options)
      .pipe(timeout(30000))
      .subscribe( response => {
        resolve('success request');
      },
      error => {
        reject(error);
      },
      () => {
        console.log('delete role.');
      });
    });
  }

  assignPermissionToRole(item: any): Observable<any> {
    return this.http
      .post<any>(environment.apiUrl + '/api/v1/admin/role/permissions', JSON.stringify(item));
  }

  removePermissionsToRole(item: any){
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(item)
    };

    return new Promise(async (resolve, reject) => {
      this.http.delete<any>(environment.apiUrl + '/api/v1/admin/role/permissions', options)
      .pipe(timeout(30000))
      .subscribe( response => {
        resolve('success request');
      },
      error => {
        reject(error);
      },
      () => {
        console.log('delete role.');
      });
    });
  }

  assignRoleToUser(item: any): Observable<any> {
    return this.http
      .post<any>(environment.apiUrl + '/api/v1/admin/role/user', JSON.stringify(item));
  }

  assignDeliveries(item: any): Observable<any> {
    return this.http
      .post<any>(environment.apiUrl + '/api/v1/admin/assigns-riders', JSON.stringify(item));
  }

  updateUser(item: any, userId): Observable<any> {
    return this.http
      .patch<any>(environment.apiUrl + `/api/v1/admin/users/${userId}`, JSON.stringify(item));
  }

  createUser(item: any): Observable<any> {
    return this.http
      .post<any>(environment.apiUrl + '/api/v1/admin/users', JSON.stringify(item));
  }

  updateDeliveryStatus(item: any): Observable<any> {
    return this.http
    .post(environment.apiUrl + '/api/v1/admin/update-deliveries-status', item, { context: new HttpContext().set(BEARER_ONLY, true) });
  }

  sequenceDeliveries(item: any): Observable<any> {
    return this.http
      .post<any>(environment.apiUrl + '/api/v1/admin/sequence-deliveries', JSON.stringify(item));
  }

  getDeliveryStatuses(): Observable<any> {
    return this.http
    .get<any>(environment.apiUrl + '/api/v1/admin/delivery/statuses');
  }

  getAssignedDeliveries(): Observable<any> {
    return this.http
    .get<any>(environment.apiUrl + '/api/v1/admin/assigned-deliveries');
  }

  getDeliveryCount(): Observable<any> {
    return this.http
    .get<any>(environment.apiUrl + '/api/v1/admin/deliveries/count');
  }

  getRiderCount(): Observable<any> {
    return this.http
    .get<any>(environment.apiUrl + '/api/v1/admin/riders/count');
  }

  getRevenueCount(): Observable<any> {
    return this.http
    .get<any>(environment.apiUrl + '/api/v1/admin/revenue/count');
  }

  getPaymentCount(): Observable<any> {
    return this.http
    .get<any>(environment.apiUrl + '/api/v1/admin/payment/count');
  }

  getSmsStatus(): Observable<any> {
    return this.http
    .get<any>(environment.apiUrl + '/api/v1/admin/status-sms');
  }

  createSmsStatus(item): Observable<any> {
    return this.http
    .post<any>(environment.apiUrl + '/api/v1/admin/status-sms', JSON.stringify(item));
  }

  updateSmsStatus(item: any, smsId): Observable<any> {
    return this.http
      .patch<any>(environment.apiUrl + `/api/v1/admin/status-sms/${smsId}`, JSON.stringify(item));
  }

  deleteSmsStatus(item: any){
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return new Promise(async (resolve, reject) => {
      this.http.delete<any>(environment.apiUrl + `/api/v1/admin/status-sms/${item?.id}`, options)
      .pipe(timeout(30000))
      .subscribe( response => {
        resolve('success request');
      },
      error => {
        reject(error);
      },
      () => {
        console.log('delete sms status.');
      });
    });
  }

  uploadDayonFile(item: any): Observable<any> {
    return this.http
    .post(environment.apiUrl + '/api/v1/admin/reconciliation/settlement/upload_dayon_file', item, { context: new HttpContext().set(BEARER_ONLY, true) });
  }

  manualReconciliation(): Observable<any> {
    return this.http
    .post(environment.apiUrl + '/api/v1/admin/reconciliation/manual-reconciliation', { context: new HttpContext().set(BEARER_ONLY, true) });
  }

  getTerritories(): Observable<any> {
    return this.http
    .get<any>(environment.apiUrl + '/api/v1/admin/territories');
  }
  createTerritories(item): Observable<any> {
    return this.http
    .post<any>(environment.apiUrl + '/api/v1/admin/territories', JSON.stringify(item));
  }
  updateTerritories(item: any, id): Observable<any> {
    return this.http
    .patch<any>(environment.apiUrl + `/api/v1/admin/territories/${id}`, JSON.stringify(item));
  }

  getRiderTerritories(territoryId: any): Observable<any> {
    return this.http
    .get<any>(environment.apiUrl + `/api/v1/admin/territories/${territoryId}/rider-territories`);
  }
  createRiderTerritories(item: any, territoryId: any): Observable<any> {
    return this.http
    .post<any>(environment.apiUrl + `/api/v1/admin/territories/${territoryId}/rider-territories`, JSON.stringify(item));
  }
  updateRiderTerritories(item: any, territoryId, id): Observable<any> {
    return this.http
    .patch<any>(environment.apiUrl + `/api/v1/admin/territories/${territoryId}/rider-territories/${id}`, JSON.stringify(item));
  }

  getUserTerritories(territoryId): Observable<any> {
    return this.http
    .get<any>(environment.apiUrl + `/api/v1/admin/territories/${territoryId}/user-territories`);
  }
  createUserTerritories(item: any, territoryId: any): Observable<any> {
    return this.http
    .post<any>(environment.apiUrl + `/api/v1/admin/territories/${territoryId}/user-territories`, JSON.stringify(item));
  }
  updateUserTerritories(item: any, territoryId, id): Observable<any> {
    return this.http
    .patch<any>(environment.apiUrl + `/api/v1/admin/territories/${territoryId}/user-territories/${id}`, JSON.stringify(item));
  }

  getTerritoryArea(territoryId): Observable<any> {
    return this.http
    .get<any>(environment.apiUrl + `/api/v1/admin/territories/${territoryId}/territory-areas`);
  }
  createTerritoryArea(item: any, territoryId: any): Observable<any> {
    return this.http
    .post<any>(environment.apiUrl + `/api/v1/admin/territories/${territoryId}/territory-areas`, JSON.stringify(item));
  }
  updateTerritoryArea(item: any, territoryId, id): Observable<any> {
    return this.http
    .patch<any>(environment.apiUrl + `/api/v1/admin/territories/${territoryId}/territory-areas/${id}`, JSON.stringify(item));
  }

  getReconciliationReportWoFilter(): Observable<any> {
    return this.http
    .get<any>(environment.apiUrl + `/api/v1/admin/reconciliation/report`);
  }

  getReconciliationReport(reconcileStatus: any, startDate: any, endDate: any): Observable<any> {
    if(!reconcileStatus){
      return this.http
      .get<any>(environment.apiUrl + `/api/v1/admin/reconciliation/report?filter[pickupBetween]=${startDate},${endDate}`);
    } else {
      return this.http
      .get<any>(environment.apiUrl + `/api/v1/admin/reconciliation/report?filter[reconcileStatus]=${reconcileStatus}&filter[pickupBetween]=${startDate},${endDate}`);
    }
    
  }

  exportReconciliationReport(reconcileStatus: any, startDate: any, endDate: any): Observable<Blob> {
    const headers = new HttpHeaders({
      'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });

    var url;
    if(!reconcileStatus){
      url = `/api/v1/admin/reconciliation/export-report?filter[pickupBetween]=${startDate},${endDate}`
    } else{
      url = `/api/v1/admin/reconciliation/export-report[reconcileStatus]=${reconcileStatus}&filter[pickupBetween]=${startDate},${endDate}`;
    }

    return this.http.get(environment.apiUrl + url, {
      headers: headers,
      responseType: 'blob',
    });
  }

  getReconciliationReportByRefId(referenceId: any): Observable<any> {
    return this.http
    .get<any>(environment.apiUrl + `/api/v1/admin/reconciliation/report?filter[referenceId]=${referenceId}`);
  }
}